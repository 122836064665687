export default [
  {
    title: 'Admin',
    icon: 'bi-shield-lock',
    children: [
      {
        title: 'Swagger',
        route: 'swagger',
        icon: 'bi-signpost-2',
        resource: 'resource_admin',
        action: 'manage',
      },
      {
        title: 'Resource Management',
        route: 'resource-management',
        icon: 'feather-command',
        resource: 'resource_admin',
        action: 'manage',
      },
      {
        title: 'Membership',
        icon: 'feather-users',
        children: [
          {
            title: 'Management',
            route: { name: 'membership-management' },
            resource: 'resource_admin',
            action: 'read',
          },
          {
            title: 'Subscriptions',
            route: { name: 'membershipsubscribers' },
            resource: 'resource_admin',
            action: 'read',
          },
          {
            title: 'Charges',
            route: { name: 'membershipcharges' },
            resource: 'resource_admin',
            action: 'read',
          },
        ],
      },
      {
        title: 'Maintenance',
        route: 'maintenance',
        icon: 'feather-server',
        resource: 'resource_admin',
        action: 'manage',
      },
      // {
      //   title: 'Folder Plan',
      //   route: 'file-manager-folder-plan',
      //   icon: 'feather-folder',
      //   resource: 'resource_admin',
      //   action: 'read',
      // },
      {
        title: 'Printer',
        icon: 'feather-printer',
        children: [
          {
            title: 'Vendors',
            route: { name: 'printer-vendors' },
            resource: 'resource_admin',
            action: 'read',
            icon: 'bi-back',
          },
          {
            title: 'SystemData',
            route: { name: 'printer-system-data' },
            resource: 'resource_admin',
            action: 'read',
            icon: 'bi-gear-fill',
          },
          {
            title: 'Test Page',
            route: { name: 'printer-test-page' },
            resource: 'resource_admin',
            action: 'read',
          },
        ],
      },
      {
        title: 'PSH Icons',
        route: 'psh-icons',
        icon: 'bi-brush',
        resource: 'resource_admin',
        action: 'manage',
      },
    ],
  },
]

export default [
  {
    title: 'Reports',
    icon: 'psh-dashboard',
    children: [
      // {
      //   title: 'Restock Recommendations',
      //   name: 'restock-recommendations',
      //   icon: 'psh-inventory4',
      //   route: 'restock-recommendations',
      //   resource: 'resource_reports',
      //   action: 'read',
      // },
      {
        title: 'Reporting Dashboard',
        name: 'amazon-reporting-dashboard',
        icon: 'psh-dashboard3',
        route: { name: 'amazon-reporting-dashboard' },
        resource: 'resource_customer_reports',
        action: 'read',
      },
      {
        title: 'Profitability',
        icon: 'psh-trade',
        children: [
          {
            title: 'By ASIN',
            name: 'amazon-asin-report',
            icon: 'psh-trade',
            route: { name: 'amazon-asin-report', params: { reportType: 'ASIN' } },
            resource: 'resource_customer_reports',
            action: 'read',
          },
          {
            title: 'By MSKU',
            name: 'amazon-msku-report',
            icon: 'psh-trade',
            route: { name: 'amazon-msku-report', params: { reportType: 'MSKU' } },
            resource: 'resource_customer_reports',
            action: 'read',
          },
          {
            title: 'By Supplier',
            name: 'amazon-supplier-report',
            icon: 'psh-trade',
            route: { name: 'amazon-supplier-report', params: { reportType: 'SUPPLIER' } },
            resource: 'resource_customer_reports',
            action: 'read',
          },
          {
            title: 'By Category',
            name: 'amazon-category-report',
            icon: 'psh-trade',
            route: { name: 'amazon-category-report', params: { reportType: 'CATEGORY' } },
            resource: 'resource_customer_reports',
            action: 'read',
          },
        ],
      },
      {
        title: 'Amazon Sales',
        name: 'amazon-sales-report',
        icon: 'psh-profit',
        route: { name: 'amazon-sales-report' },
        resource: 'resource_customer_reports',
        action: 'read',
      },
      {
        title: 'Amazon Reimbursements',
        name: 'amazon-reimbursements-report',
        icon: 'psh-payment',
        route: { name: 'amazon-reimbursements-report' },
        resource: 'resource_customer_reports',
        action: 'read',
      },
      {
        title: 'Amazon Returns',
        name: 'amazon-returns-report',
        icon: 'psh-return',
        route: { name: 'amazon-returns-report' },
        resource: 'resource_customer_reports',
        action: 'read',
      },
      {
        title: 'Amazon Profit & Loss',
        name: 'amazon-profit-and-loss',
        icon: 'psh-payment',
        route: { name: 'amazon-profit-and-loss' },
        resource: 'resource_admin',
        action: 'read',
      },
      {
        title: 'Additional Incomes',
        name: 'amazon-incomes',
        icon: 'bi-cash-coin',
        route: { name: 'amazon-incomes' },
        resource: 'resource_customer_reports',
        action: 'read',
      },
      {
        title: 'Additional Expenses',
        name: 'amazon-expenses',
        icon: 'bi-cash-coin',
        route: { name: 'amazon-expenses' },
        resource: 'resource_customer_reports',
        action: 'read',
      },
      // {
      //   title: 'ASIN Profitability',
      //   name: 'amazon-asin-report',
      //   icon: 'psh-trade',
      //   route: { name: 'amazon-asin-report', params: { reportType: 'ASIN' } },
      //   resource: 'resource_customer_reports',
      //   action: 'read',
      // },
      // {
      //   title: 'MSKU Profitability',
      //   name: 'amazon-msku-report',
      //   icon: 'psh-trade',
      //   route: { name: 'amazon-msku-report', params: { reportType: 'MSKU' } },
      //   resource: 'resource_customer_reports',
      //   action: 'read',
      // },
      // {
      //   title: 'Supplier Profitability',
      //   name: 'amazon-supplier-report',
      //   icon: 'psh-trade',
      //   route: { name: 'amazon-supplier-report', params: { reportType: 'SUPPLIER' } },
      //   resource: 'resource_customer_reports',
      //   action: 'read',
      // },
      // {
      //   title: 'Category Profitability',
      //   name: 'amazon-category-report',
      //   icon: 'psh-trade',
      //   route: { name: 'amazon-category-report', params: { reportType: 'CATEGORY' } },
      //   resource: 'resource_customer_reports',
      //   action: 'read',
      // },
      // {
      //   title: 'Order Metrics',
      //   name: 'order-metrics',
      //   icon: 'psh-metrics',
      //   route: 'order-metrics',
      //   resource: 'resource_reports',
      //   action: 'read',
      // },
      // {
      //   title: 'Uploaded Items Amazon Ranks',
      //   name: 'amazon-ranks',
      //   icon: 'psh-reports',
      //   route: 'amazon-ranks',
      //   resource: 'resource_reports',
      //   action: 'read',
      // },
      // {
      //   title: 'Amazon Monthly Sales Metrics',
      //   name: 'amazon-monthly-sales',
      //   icon: 'psh-profit',
      //   route: 'amazon-monthly-sales',
      //   resource: 'resource_amazon_ranks',
      //   action: 'read',
      // },
      {
        title: 'Historical Inventory Values',
        name: 'amazon-historical-inventory-values',
        icon: 'psh-shelves',
        route: 'amazon-historical-inventory-values',
        resource: 'resource_customer_reports',
        action: 'read',
      },
      // {
      //   title: 'Amazon Monthly Sales Snapshots',
      //   name: 'amazon-monthly-sales-snapshots',
      //   icon: 'psh-trade',
      //   route: 'amazon-monthly-sales-snapshots',
      //   resource: 'resource_reports',
      //   action: 'read',
      // },
      // {
      //   title: 'Amazon Item Cost Stats',
      //   name: 'amazon-item-cost-stats',
      //   icon: 'psh-bill',
      //   route: 'amazon-item-cost-stats',
      //   resource: 'resource_private_report1',
      //   action: 'read',
      // },
      // {
      //   title: 'ASINBased',
      //   route: 'asin-based-reports',
      //   icon: 'HashIcon',
      // },
      // {
      //   title: 'UPCBased',
      //   route: 'upc-based-report',
      //   icon: 'TagIcon',
      // },
      // {
      //   title: 'StoreBased',
      //   route: 'store-based-reports',
      //   icon: 'HomeIcon',
      // },
      // {
      //   title: 'SalesStats',
      //   route: 'sale-stats',
      //   icon: 'DollarSignIcon',
      // },
    ],
  },
]

import * as R from 'ramda'
import { JsonToQueryString } from '@robustshell/utils/index'

export default class Pageable {
  options= {
    paging: {
      pageSize: 50,
    },
    pager: {
      offset: 0,
      pageNumber: 1,
      allowedPageSizes: [10, 20, 50],
      showInfo: true,
      showNavigationButtons: true,
      showPageSizeSelector: true,
      visible: true,
    },
    size: 200,
    numberOfElements: null,
    totalElements: null,
  }

  constructor(options) {
    this.options = Object.assign(this.options, options)
  }

  toCreatePageable() {
    return {
      pageSize: this.options.paging.pageSize,
      pageNumber: this.options.pager.pageNumber - 1,
      allowedPageSizes: this.options.pager.allowedPageSizes,
      showInfo: this.options.pager.showInfo,
      showNavigationButtons: this.options.pager.showNavigationButtons,
      showPageSizeSelector: this.options.pager.showPageSizeSelector,
      visible: this.options.pager.visible,
    }
  }

  toCreatePayload() {
    return {
      pageable: this.toCreatePageable(),
      totalElements: this.totalElements,
    }
  }

  toCreateQueryString() {
    return JsonToQueryString({
      page: this.options.pager.pageNumber - 1,
      size: this.options.size,
    })
  }

  setContext(pageable = {}) {
    this.options.pager.totalElements = R.is(Number, pageable.totalElements) ? pageable.totalElements : 0
  }

  setPageNumber(value) {
    if (R.is(Number, value) && value > 0) {
      this.pageNumber = value
    } else {
      this.pageNumber = 1
    }
  }

  setNumberOfElements(value) {
    this.pageSize = R.is(Number, value) ? value : 20
    this.offset = this.pageSize
  }

  setTotalElementsElements(value) {
    this.totalElements = R.is(Number, value) ? value : 0
  }
}

<template>
  <b-nav-form class="mr-1">
      <b-avatar
        :badge="notificationCount > 0 ? notificationCount.toString(): ''"
        :badge-variant="notificationCount > 0 ? 'danger': ''"
        badge-top badge-offset="-2px"
        icon="bell"
        variant="primary"
        button
        @click="getNotificationContent()"
      />
    <div>
      <system-notification-content :notification-count="notificationCount" />
    </div>
  </b-nav-form>
</template>

<script>
/* eslint-disable no-unused-vars */

import notificationService from '@/http/requests/notification/notificationService'
import store from '@/store'
import moment from 'moment'
import SystemNotificationContent from './SystemNotificationContent.vue'

const DELAY = process.env.VUE_APP_NOTIFICATION_DELAY // minute
export default {
  components: {
    SystemNotificationContent,
  },
  data() {
    return {
      notificationCount: 0,
      readedIds: [],
      interval: null,
    }
  },
  computed: {
    notificationReadInfo() {
      return store.getters['systemNotification/getNotificationReadInfo']
    },
    frequency() {
      const readTime = moment(this.notificationReadInfo.readTime)
      const nextReadTime = moment(this.notificationReadInfo.nextReadTime)
      const duration = moment.duration(nextReadTime.diff(readTime))
      return duration.asMilliseconds()
    },
  },
  created() {
    const notificationReadInfo = store.getters['systemNotification/getNotificationReadInfo']
    if (!notificationReadInfo.readTime) {
      const now = moment().valueOf()
      const then = moment(now).add(DELAY, 'minute').valueOf()
      store.dispatch('systemNotification/setNotificationReadInfo', { readTime: now, nextReadTime: then })
      this.getNotificationCount()
    }
  },
  mounted() {
    const self = this
    this.interval = setInterval(() => {
      self.popNotificationUnRead()
    }, self.frequency)
  },
  methods: {
    getNotificationContent() {
      this.$root.$emit(
        'bv::toggle::collapse',
        'system-notifications-sidebar',
      )
    },
    popNotificationUnRead() {
      const now = moment().valueOf()
      const then = moment(now).add(DELAY, 'minute').valueOf()
      const nextReadTime = moment(this.notificationReadInfo.nextReadTime)
      const newDuration = moment.duration(nextReadTime.diff(now))
      if (newDuration.asMilliseconds() <= 0) {
        store.dispatch('systemNotification/setNotificationReadInfo', { readTime: now, nextReadTime: then })
        this.getNotificationCount()
      }
    },
    getNotificationCount() {
      this.notificationList = []
      const userData = this.getUserData()
      if (userData && userData.notificationSubscribed) {
        notificationService.popNotificationUnReadCount().then(result => {
          this.notificationCount = result.data
        })
      } else {
        clearInterval(this.interval)
      }
    },
    getUserData() {
      return JSON.parse(localStorage.getItem('userData'))
    },
  },
}
</script>

<style></style>

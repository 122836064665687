<template>
  <li
    v-if="$can(item.action, item.resource)"
    class="nav-item"
    :class="{
      'active': isActive,
      'disabled': item.disabled
    }"
  >
    <router-link
      v-bind="linkProps"
      class="d-flex align-items-start"
    >
      <p-icon :name="item.icon" />
      <span class="menu-title text-truncate">{{ item.title }}</span>
      <span
        v-if="item.tag"
        :class="`badge-${item.tagVariant}` || 'badge-primary'"
        class="badge badge-pill mr-1 ml-auto"
      >
        {{ item.tag }}
      </span>
    </router-link>
  </li>
</template>

<script>
// import { ref } from '@vue/composition-api'
import useVerticalNavMenuLink from './useVerticalNavMenuLink'
import mixinVerticalNavMenuLink from './mixinVerticalNavMenuLink'

export default {
  mixins: [mixinVerticalNavMenuLink],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { isActive, linkProps, updateIsActive } = useVerticalNavMenuLink(props.item)

    return {
      isActive,
      linkProps,
      updateIsActive,
    }
  },

}
</script>

<template>
<div class="d-flex justify-content-between clearfix mb-0">
  <div class="d-block d-md-inline-block align-self-center">
    COPYRIGHT © {{ new Date().getFullYear() }} PrepShipHub
  </div>
  <div class="mx-auto d-none d-md-block">
    <common-tenant-logo height="36px" :is-link="true" :logo-type="'FOOTER_LOGOS'" />
  </div>
  <div class="d-none d-md-block align-self-center">
    Version: {{ versionNumber }} Release Date: {{ releaseDate }}
  </div>
  <div v-if="true" class="d-md-block align-self-center mx-half">
    <technical-support />
  </div>
  <div class="d-md-block align-self-center mx-half">
    <!--
      <app-chat-notification v-if="hasPermission" />
    -->
  </div>
  <div class="d-none d-md-block align-self-center w-100px ">
    &nbsp;
  </div>
</div>
</template>

<script>
// TODO: use hasPermission app-chat-notification component
// import AppChatNotification from '@core/layouts/components/AppChatNotification.vue'
import CommonTenantLogo from './CommonTenantLogo.vue'
import TechnicalSupport from './TechnicalSupport.vue'

export default {
  components: {
    CommonTenantLogo,
    // AppChatNotification,
    TechnicalSupport,
  },
  data: () => ({
    versionNumber: '1.0.0',
    releaseDate: null,
  }),
  computed: {
    hasPermission() {
      return this.$can('read', 'resource_chat')
    },
  },
  mounted() {
    this.version()
  },
  methods: {
    version() {
      this.versionNumber = '1.4.12'
      const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: false,
      }
      this.releaseDate = new Intl.DateTimeFormat('en-US', options).format(1714421474588)
    },
  },
}
</script>

<template>
<div>
  <b-sidebar
    id="system-notifications-sidebar"
    refs="systemNotificationsSidebar"
    bg-variant="white"
    shadow
    backdrop
    right
    width="30%"
    no-close-on-backdrop
    aria-modal="true"
    @shown="onShown"
  >
    <template #header="{ hide }">
      <div class="d-flex justify-content-between p-1 w-100">
        <div class="flex-grow-1">
          <strong>Notifications </strong>
        </div>
        <div class="mr-1">
            <dx-util-button
              type="default"
              text="Show All Notifications"
              @click="onShowAllNotifications"
            />
        </div>
        <div>
          <dx-util-button
              type="normal"
              icon="remove"
              @click="hide"
            />
        </div>
      </div>
    </template>
    <template #default>
      <dx-util-scroll-view
        show-scrollbar="onHover"
        :height="scroolHeight"
      >
        <div class="row m-0 p-0">
          <div
            v-for="(item, index) in notifications"
            :key="item.id"
            class="col-sm-12"
            :class="index != 0 ? 'mt-2' : 'mt-1'"
          >
            <div class="d-flex justify-content-start align-items-center">
              <b-avatar
                size="40"
                rounded
                :src="notificationIcon"
                class="mr-1"
                style="backgroun-color: transparent"
              />
              <div>
                <h6 class="mb-0">
                  {{ formatedTopic(item.topic) }}
                </h6>
                <span>
                  <small class="text-muted">
                    {{ formatedDate(item.creationTime) }}
                  </small>
                </span>
              </div>
              <div class="ml-auto" style="cursor: pointer" @click="onReadNotification(item.read, item.id)">
                <b-icon
                  :icon="item.read ? 'circle-fill' : 'circle'"
                  :class="item.read ? 'text-success' : 'text-warning'"
                  scale="1"
                />
              </div>
            </div>
            <div>
              <span v-html="item.message" />
              <a v-if="item.link" class="text-primary" @click="onClickOpenProblem(item.link)">See more details</a>
            </div>
          </div>
        </div>
      </dx-util-scroll-view>
    </template>
  </b-sidebar>
</div>
</template>
<script>
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import notificationService from '@/http/requests/notification/notificationService'
import { formatDateTime } from '@core/utils/filter'
import { ValueToEnum } from '@robustshell/utils/index'

export default {
  props: {
    notificationCount: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      // eslint-disable-next-line global-require
      notificationIcon: require('@/assets/images/svg/notification_alarm.svg'),
      notifications: [],
      readedIds: [],
      position: { of: '#system-notifications-sidebar' },
    }
  },
  computed: {
    scroolHeight() {
      return window.innerHeight - 140
    },
  },
  methods: {
    formatedDate(date) {
      return formatDateTime(date, {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      })
    },
    formatedTopic(topic) {
      const enumItem = ValueToEnum(this.$enums.NotificationTypeEnum, topic)
      return enumItem.text
    },
    readMore(message) {
      let readMessage = message
      if (readMessage.length > 120) {
        readMessage = readMessage.substring(0, 120).concat('...')
      }
      return readMessage
    },
    onShown() {
      this.getAllNotifications()
    },
    onReadNotification(read, id) {
      if (!read) {
        notificationService.updateNotificationList([id]).then(result => {
          if (result.data) {
            this.getAllNotifications()
          }
        })
      }
    },
    async getAllNotifications() {
      this.notifications.splice(0, this.notifications.length)
      const getNotifications = await notificationService.popNotification([])
      const notificationCount = getNotifications.length
      if (notificationCount > 30) {
        getNotifications.splice(30, getNotifications.length - 30)
      }
      getNotifications.forEach(item => {
        const message = JSON.parse(item.message)
        const notification = {
          avatarType: item.avatarType,
          companyId: item.companyId,
          creationTime: item.creationTime,
          entityId: item.entityId,
          icon: item.icon,
          id: item.id,
          message: this.$options.filters.cleanNotification(message.message),
          link: message.link,
          publisherUserId: item.publisherUserId,
          read: item.read,
          readDate: item.readDate,
          severity: item.severity,
          storeId: item.storeId,
          targetUserId: item.targetUserId,
          topic: item.topic,
        }
        this.notifications.push(notification)
      })
    },
    onShowAllNotifications() {
      this.$root.$emit('bv::toggle::collapse', 'system-notifications-sidebar')
      this.$router.push({ name: 'notifications' })
    },
    onClickOpenProblem(link) {
      if (link) {
        this.$router.push(link)
      }
    },
  },
}
</script>
<style lang="scss">
#system-notifications-sidebar{
  .b-sidebar-body {
    overflow-y: hidden !important;
  }
  .notification-footer{
    text-align: center;
  }
  .read-more {
    width: 98% !important;
    max-width: 100% !important;
  }
}
</style>

export default [
  {
    title: 'Outbound',
    icon: 'bi-truck',
    children: [
      {
        title: 'FBA / MFN',
        icon: 'bi-circle-square',
        children: [
          {
            title: 'FBA Force',
            route: 'fba-shipments',
            icon: 'bi-box',
            resource: 'resource_outbound_fba_force_main',
            action: 'read',
          },
          {
            title: 'Shipment Batches',
            route: 'fba-outbound-shipping',
            icon: 'bi-bounding-box-circles',
            resource: 'resource_outbound_shipment_batches',
            action: 'read',
            params: {
              channel: 'fba',
            },
          },
          {
            title: 'Open Shipment Plans',
            route: 'fba-pending-batches',
            icon: 'bi-hourglass-split',
            resource: 'resource_outbound_shipment_batches',
            action: 'read',
            params: {
              channel: 'fba',
            },
          },
          {
            title: 'Bill of Ladings',
            route: 'fba-bols',
            icon: 'psh-package-3',
            resource: 'resource_outbound_fba_bill_of_lading',
            action: 'read',
          },
        ],
      },
      {
        title: 'WFS',
        icon: 'bi-exclude',
        children: [
          {
            title: 'WFS Force',
            route: 'wfs-account-select',
            icon: 'bi-box',
            resource: 'resource_wfs',
            action: 'read',
          },
          {
            title: 'Shipment Batches',
            route: 'wfs-outbound-shipping',
            icon: 'bi-bounding-box-circles',
            resource: 'resource_outbound_shipment_batches',
            action: 'read',
            params: {
              channel: 'wfs',
            },
          },
          {
            title: 'Open Shipment Plans',
            route: 'wfs-pending-batches',
            icon: 'bi-hourglass-split',
            resource: 'resource_outbound_shipment_batches',
            action: 'read',
            params: {
              channel: 'wfs',
            },
          },
          {
            title: 'Shipping Templates',
            route: 'wfs-shipping-templates',
            icon: 'bi-filter-square',
            resource: 'resource_admin',
            action: 'read',
          },
        ],
      },
      {
        title: 'Merchant Fulfillment',
        icon: 'bi-box-seam-fill',
        children: [
          {
            title: 'Integrations',
            route: 'fbm-force',
            icon: 'psh-outbound',
            resource: 'resource_outbound_fbm_force_main',
            action: 'read',
          },
          {
            title: 'Shipments',
            route: 'fbm-shipments',
            icon: 'bi-bounding-box',
            resource: 'outbound_fbm_shipment',
            action: 'read',
          },
        ],
      },
    ],
  },
]

import axios from '@axios'
import endpoints from '@/http/endpoints/index'

export default {
  // get all cost groups
  // eslint-disable-next-line no-unused-vars
  async serviceFolderMultipartFileUpload(serviceName, companyId, entityId, documentClass, data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .put(endpoints.filemanagement.FileManagementController.serviceFolderMultipartFileUpload(serviceName, companyId, entityId, documentClass), data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },
  async getCompanyFolderPathByAccountNo(accountNo) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.filemanagement.FileManagementController.getCompanyFolderPathByAccountNo(accountNo))
        .then(response => {
          resolve({
            data: response.data,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },
  async getCurrentCompanyType() {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.filemanagement.FileManagementController.getCurrentCompanyType())
        .then(response => {
          resolve({
            data: response.data,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },
  serviceFolderMultipartFileDownload(fileCloudId) {
    const promise = new Promise((resolve, reject) => {
      axios({
        url: endpoints.filemanagement.FileManagementController.serviceFolderMultipartFileDownload(fileCloudId),
        method: 'GET',
        responseType: 'blob',
      })
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },
  serviceFolderMultipartFileDelete(fileCloudId) {
    const promise = new Promise((resolve, reject) => {
      axios.delete(endpoints.filemanagement.FileManagementController.serviceFolderMultipartFileDelete(fileCloudId))
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },
  async serviceFolderDirectoryLists(serviceName, companyId, entityId, documentClass) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.filemanagement.FileManagementController.serviceFolderDirectoryLists(serviceName, companyId, entityId, documentClass))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },
}

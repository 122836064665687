<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user user-list"
    no-caret
    @shown="onShown"
  >
    <template #button-content>
      <div class="d-flex flex-column d-none user-nav justify-content-center text-primary">
        <div v-if="userData.tenantType !== TenantTypeEnum.INDIVIDUAL.value " class="user-name font-weight-bold mb-0 mr-1 align-self-center text-center">
          {{ userData.tenantId }}
        </div>
        <div class="user-name font-weight-normal mb-0 mr-1 align-self-center text-center">
          {{ userData.fullname || userData.username }}
        </div>
      </div>
      <b-avatar
        size="40"
        :src="userData.avatar"
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      >
        <feather-icon
          v-if="!userData.avatar"
          icon="UserIcon"
          size="22"
        />
      </b-avatar>
    </template>
    <b-dropdown-item
      :to="{ name: 'pages-account-setting' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="SettingsIcon"
        class="mr-50"
      />
      <span>Settings</span>
    </b-dropdown-item>
    <b-dropdown-divider />
    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="logout"
    >
      <feather-icon
        size="16"
        icon="LogOutIcon"
        class="mr-50"
      />
      <span>Logout</span>
    </b-dropdown-item></b-nav-item-dropdown>
</template>

<script>
import { initialAbility } from '@/libs/acl/config'
import { avatarText } from '@core/utils/filter'
import TenantTypeEnum from '@/enums/tenantType.enum'

export default {
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      avatarText,
      TenantTypeEnum,
    }
  },

  methods: {
    onShown() {
      const item = document.getElementsByClassName('dropdown-user')
      item[0].lastElementChild.removeAttribute('style')
    },
    logout() {
      const version = localStorage.getItem('version-data-uid')
      localStorage.clear()
      localStorage.setItem('version-data-uid', version)
      this.$ability.update(initialAbility)
      this.$router.push({ name: 'auth-login' })
    },
  },
}
</script>

<style scoped>
.user-list {
  list-style-type: none;
    align-items: center;
    padding-top: 5px;
}
</style>

<template>
  <div class="d-flex align-items-center py-half px-1">
    <p-icon :name="itemName === 'Photo' ? 'feather-image' : 'psh-barcode'" size="24px" />
    <dx-util-text-box
      :value="itemName"
      :read-only="true"
    />
  </div>
</template>

<script>
export default {
  props: {
    fieldData: {
      type: [String, null],
      required: true,
    },
  },
  computed: {
    itemName() {
      return this.fieldData
    },
  },
}
</script>

<style lang="scss" scoped>

</style>

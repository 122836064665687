<template>
  <div class="container-fluid">
    <div class="card card-psh border pb-0 mb-0">
      <div class="bg-light-primary rounded-top py-1 px-0">
        <div class="psh-header mb-0 d-flex align-items-center px-2">
          <div class="psh-title border-0 px-0">
            <p-icon name="bi-cloud-upload" size="48px" color="primary" class="p-0" />
          </div>
          <div class="d-flex flex-column">
            <h4 class="card-title mb-25">
              {{ cardTitle }}
            </h4>
            <p class="card-text mb-0">
              {{ cardSubtitle }}
            </p>
          </div>
          <div class="flex-grow-1"></div>
          <div v-if="uploadDocumentClass!=='NONE_CLASS'" class="mr-1 d-flex align-items-center">
            <label for="activeTab" class="mr-1">Folder:</label>
            <dx-util-select-box
              id="activeTab"
              v-model="activeTab"
              :data-source="uploadDocumentClasses"
              field-template="fileFieldTemplate"
              :read-only="onlyOneOption"
              @value-changed="tabChange"
            >
            <template #fileFieldTemplate="{ data }">
              <FileField :field-data="data" />
            </template>
            <template #item="{ data }">
              <FileItem :item-data="data" />
            </template>
            </dx-util-select-box>
          </div>
          <div class="ml-auto">
            <dx-util-button
              type="default"
              icon="pulldown"
              @click="onRefreshMultiplePartFile"
            />
          </div>
        </div>
      </div>
      <div :key="rerenderKey" class="card-body">
         <div class="overlay">
          <div class="row pb-1">
            <div class="col-md-12">
              <div class="d-flex flex-row justify-content-start">
                <div class="flex-grow-1">
                  <dx-util-file-uploader
                    v-if="showButton"
                    :allowed-file-extensions="allowedFileExtensions"
                    :upload-headers="uploadHeaders"
                    upload-method="PUT" name="file"
                    :show-file-list="showFileList"
                    :on-files-uploaded="onFilesUploaded"
                    :on-uploaded="onUploaded"
                    :on-upload-aborted="onUploadAborted"
                    :on-upload-started="onUploadStarted"
                    :on-upload-error="onUploadError"
                    :rtl-enabled="false"
                    :on-value-changed="onValueChanged"
                    :multiple="true"
                    upload-mode="instantly"
                    :upload-url="uploadUrl"
                  />
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <dx-util-list :data-source="fileList">
                <template #item="{ data: item }">
                <div class="d-flex align-items-center border-bottom py-1">
                  <div class="col-lg-10 text-wrap" @click="showFile(item)">
                  {{ formatDates(item.creationTime) }} - {{ item.lastName }}
                </div>
                <div>
                  <dx-util-button
                    type="success"
                    icon="download"
                    class="mr-1"
                    @click="onDownloadFile(item)"
                  />

                  <dx-util-button
                    :visible="showButton"
                    type="danger"
                    icon="trash"
                    @click="onDeleteFile(item)"
                  />
                </div>
                </div>
                </template>

              </dx-util-list>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable indent */
// eslint-disable-next-line no-unused-vars
import fileManagerService from '@/http/requests/filemanagement/fileManagerService'
import GridBase from '@core/dev-extreme/mixins/grid/gridBase'
import { formatDateTime } from '@core/utils/filter'
import { Notify } from '@/@robustshell/utils'
import FileField from './FileField.vue'
import FileItem from './FileItem.vue'

const baseURL = process.env.VUE_APP_API_URL
export default {
  components: {
    FileField,
    FileItem,
  },
  mixins: [GridBase],
  props: {
    cardTitle: {
      type: String,
      default: 'File Upload',
    },
    cardSubtitle: {
      type: String,
      default: 'Upload files related to this service',
    },
    companyId: {
      type: Number,
      required: true,
    },
    serviceFolder: {
      type: String,
      required: true,
    },
    entityFolderId: {
      type: [String, Number],
      required: true,
    },
    uploadDocumentClasses: {
      type: Array,
      required: true,
    },
    uploadDocumentClass: {
      type: String,
      required: true,
      default: 'NONE_CLASS',
    },
    title: {
      type: String,
      required: false,
      default: ' ',
    },
    fromShipmentBatch: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showFileList: true,
      showOverlay: false,
      file: null,
      fileList: null,
      shippingLabelCount: 0,
      activeTab: null,
      tabIndex: 0,
      isIndividualTenant: false,
      allowedFileExtensions: ['.png', '.jpg', '.jpeg', '.pdf'],
      rerenderKey: 0,
    }
  },
  computed: {
    uploadHeaders() {
      const accessToken = localStorage.getItem('accessToken')
      // eslint-disable-next-line no-shadow
      const bearer = `Bearer ${accessToken}`
      return {
        'Accept-Language': 'en',
        Authorization: bearer,
      }
    },
    uploadUrl() {
      const activeDocument = this.activeTab ? this.activeTab : this.uploadDocumentClass
      const file = `${baseURL}/api/file-system/devx-file-manager/service-folder/${this.serviceFolder}/${this.companyId}/${this.entityFolderId}/${activeDocument}/upload`
      return file
    },
    hasPermission() {
      const permission = this.$can('read', 'resource_customer_account_no')
      return permission
    },
    showButton() {
      let isVisible = true
      if (this.fromShipmentBatch) {
        if (!this.hasPermission && !this.isInvidualTenant) {
          isVisible = false
        }
      }
      return isVisible
    },
    onlyOneOption() {
      return this.uploadDocumentClasses?.length === 1
    },
  },
  watch: {
    companyId() {
      this.changeProp()
    },
    serviceFolder() {
      this.changeProp()
    },
    entityFolderId() {
      this.changeProp()
      if (this.uploadDocumentClass === 'BATCH_PRO_NUMBER') {
        this.fileList = []
        this.rerenderKey += 1
      }
    },
  },
  created() {
    // eslint-disable-next-line no-undef
    this.firstActiveTabSelect()
    const userData = JSON.parse(localStorage.getItem('userData'))
    if (userData.tenantType === 'INDIVIDUAL') {
      this.isIndividualTenant = true
    }
  },
  methods: {
    showFile(item) {
      const fileType = item?.lastName?.split('.').pop()
      let itemURL = ''
      fileManagerService.serviceFolderMultipartFileDownload(item.id)
        .then(response => {
          itemURL = window.URL.createObjectURL(new Blob([response.data], { type: response.data.type }))
          if (fileType === 'pdf') {
            // window.open(itemURL, '_blank')
            this.$swal({
            title: false,
            html: `<embed src="${itemURL}" type="application/pdf" style="height:80vh; width:100% ">`,
            showCancelButton: false,
            showConfirmButton: false,
            width: '60%',
            customClass: {
              container: 'file-delete-warn',
              popup: 'file-popup',
            },
          })
          } else {
            this.$swal({
            title: false,
            html: `<img src="${itemURL}" style=height:100% width:100% ">`,
            showCancelButton: false,
            showConfirmButton: false,
            customClass: {
              container: 'file-delete-warn',
              popup: 'file-popup',
            },
          })
          }
      })
    },
    onUploadStarted(e) {
      Notify.info(`Upload started ${e.file.name} `)
    },
    onUploaded(e) {
      Notify.info(`Upload file ${e.file.name} send to server awaiting approval from server`)
    },
    onUploadAborted(e) {
      Notify.warning(`Upload file ${e.file.name} aborted`)
    },
    onUploadError(e) {
      Notify.error(`Upload file ${e.file.name} not uploaded.`)
    },
    formatDates(date) {
      return formatDateTime(date, {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      })
    },
    firstActiveTabSelect() {
      if (this.uploadDocumentClasses.length === 1) {
        this.activeTab = this.uploadDocumentClasses[0]
      }
      if (this.uploadDocumentClass === 'Photo') {
        this.activeTab = 'Photo'
      }
      if (this.uploadDocumentClass === 'ShippingLabel') {
        this.activeTab = 'ShippingLabel'
      }
      this.loadUploadedFileList()
    },
    tabChange(i) {
      this.activeTab = i.value
      this.loadUploadedFileList()
    },
    changeProp() {
      this.loadUploadedFileList()
    },
    loadUploadedFileList() {
      if (!this.serviceFolder) return
      if (!this.companyId) return
      if (this.serviceFolder) {
        if (!this.entityFolderId) return
      }
      const activeDocument = this.activeTab ? this.activeTab : this.uploadDocumentClass
      fileManagerService.serviceFolderDirectoryLists(this.serviceFolder, this.companyId, this.entityFolderId, activeDocument)
          .then(response => {
            this.fileList = response.data
            if (this.activeTab === 'ShippingLabel') {
              this.shippingLabelCount = response.data.length
            }
            this.showFileList = false
          })
    },
    onFilesUploaded(e) {
      Notify.info('File(s) successfully uploaded please wait file list will updates.')
      this.loadUploadedFileList()
    },
    onValueChanged(e) {
      if (e?.value?.length === 1) {
        const fileTypeExtension = '.'.concat(e?.value[0]?.name.split('.').pop())
        if (this.allowedFileExtensions.findIndex(i => i === fileTypeExtension) < 0) {
          Notify.error(`File format ${fileTypeExtension} is not supported. Supported file formats: {png, jpg, jpeg, pdf}`)
        }
      }
    },
    onDownloadFile(item) {
      this.showOverlay = true
      fileManagerService.serviceFolderMultipartFileDownload(item.id)
          .then(response => {
            const fileURL = window.URL.createObjectURL(new Blob([response.data], { type: response.data.type }))
            const fileLink = document.createElement('a')
            fileLink.href = fileURL
            fileLink.setAttribute('download', item.lastName)
            document.body.appendChild(fileLink)
            fileLink.click()
            this.loadUploadedFileList()
          })
          .finally(() => {
            this.showOverlay = false
          })
    },
    onDeleteFile(item) {
      if (this.uploadDocumentClass === 'BATCH_PRO_NUMBER') {
        Notify.error('You cannot delete this file in this page. Please go to Outbound > Shipment Batches to the related batch.')
        return
      }
      // eslint-disable-next-line no-unused-vars
      const self = this
      this.$swal({
        title: 'Are you sure you want to delete this file ?',
        text: 'You won\'t be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Delete',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
          container: 'file-delete-warn',
        },
        buttonsStyling: false,
      })
          .then(result => {
            if (result.value) {
              self.showOverlay = false
              fileManagerService.serviceFolderMultipartFileDelete(item.id)
                  .then(() => {
                    this.loadUploadedFileList()
                  })
                  .finally(() => {
                    self.showOverlay = false
                  })
            }
          })
    },
    onRefreshMultiplePartFile() {
      this.loadUploadedFileList()
    },
  },
}
</script>
<style lang="scss">
.file-delete-warn {
  z-index: 1000000;
}
.file-popup {
  padding: 0 0 0 0 !important;
}
#uploadDocumentClassTab {
  .nav-pills {
    margin-top: 0.571rem;
    .nav-item {
      margin-right: 0.286rem;
      .nav-link {
        padding-left: 0.571rem;
        padding-right: 0.571rem;
        background-color: rgba(41, 128, 185, 0.5);
        &.active{
          background-color: #2980b9;
        }
      }
    }
  }
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-header d-none d-sm-block",attrs:{"id":"app-breadcrumb"}},[(_vm.$route.meta.breadcrumb || _vm.$route.meta.pageTitle)?_c('div',{staticClass:"app-breadcrumb-padding"},[_c('div',{staticClass:"d-flex flex-row justify-content-start align-items-center"},[_c('div',{staticClass:"align-self-center"},[_c('h6',{staticClass:"content-header-title float-left pr-1 mb-0"},[_vm._v(" "+_vm._s(_vm.$route.meta.pageTitle)+" ")])]),_c('div',{staticClass:"breadcrumb flex-grow-1"},[_c('router-link',{attrs:{"to":"/"}},[_c('p-icon',{staticClass:"align-text-top",attrs:{"name":"feather-home","size":"14px"}})],1),_c('ul',{staticClass:"breadcrumb"},_vm._l((_vm.$route.meta.breadcrumb),function(item){return _c('li',{key:item.text},[(item.withQuery)?_c('router-link',{attrs:{"to":{ name: item.name,
              params: {
                accountNo: _vm.$route.query.accountNo,
                companyId: _vm.$route.query.companyId,
                companyName: _vm.$route.query.companyName,
              },
              query: {
                qAccountNo: _vm.$route.query.qAccountNo,
                qWarehouseId: _vm.$route.query.qWarehouseId,
                qStoreId: _vm.$route.query.qStoreId,
                qStatus: _vm.$route.query.qStatus,
                },
              }}},[_vm._v(" "+_vm._s(item.text)+" ")]):(item.to)?_c('router-link',{attrs:{"to":item.to}},[_vm._v(" "+_vm._s(item.text)+" ")]):_c('span',[_vm._v(" "+_vm._s(item.text)+" ")])],1)}),0)],1),(_vm.remainingTrialDays && _vm.remainingTrialDays.length > 0)?_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"badge badge-light-warning mr-half"},[_vm._v(" "+_vm._s(_vm.firstPlan.trialRemaining)+" days left ")]),_vm._v(" for "+_vm._s(_vm.firstPlan.planName)+" "),_c('div',{staticClass:"pulse",attrs:{"id":"trialRemaining"}},[_c('p-icon',{attrs:{"name":"bi-exclamation-circle-fill"}})],1)]):_vm._e()])]):_vm._e(),_c('div',[(_vm.remainingTrialDays && _vm.remainingTrialDays.length > 1)?_c('dx-util-popover',{attrs:{"width":300,"target":"#trialRemaining","show-event":"dxhoverstart","hide-event":"dxhoverend"}},_vm._l((_vm.remainingTrialDays),function(item,index){return _c('div',{key:index,staticClass:"d-flex justify-content-between p-half"},[_vm._v(" "+_vm._s(item.planName)+" "),_c('span',{staticClass:"badge badge-light-warning"},[_vm._v(_vm._s(item.trialRemaining)+" days left")])])}),0):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
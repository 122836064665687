export default [
  {
    title: 'Account',
    icon: 'feather-user',
    children: [
      {
        title: 'My Company',
        route: { name: 'route-account-my-company', params: { companyId: 0 } },
        icon: 'feather-home',
        resource: 'resource_clients_my_company',
        action: 'read',
      },
      {
        title: 'Notifications',
        route: { name: 'notifications' },
        icon: 'feather-bell',
        resource: 'resource_account_notifications',
        action: 'read',
      },
      {
        title: 'Company Credentials',
        route: { name: 'route-account-company-credentials' },
        icon: 'feather-key',
        resource: 'resource_account_company_credentials',
        action: 'read',
      },
      {
        title: 'My Plans',
        route: 'route-account-plans-my-plans',
        icon: 'psh-payment',
        resource: 'resource_management_customer_plan_enrollment',
        action: 'read',
      },
      {
        title: 'Accounting',
        icon: 'feather-dollar-sign',
        children: [
          {
            title: 'Invoices',
            icon: 'feather-file-text',
            route: 'accounting-invoice-list',
            resource: 'resource_accounting_invoice_list',
            action: 'read',
          },
          {
            title: 'Invoice Preview',
            route: 'finance-tools',
            icon: 'feather-dollar-sign',
            resource: 'resource_management_finance_tools',
            action: 'read',
          },
          {
            title: 'Charges',
            route: 'route-customer-charge',
            resource: 'resource_account_customer_charge',
            action: 'read',
          },
          {
            title: 'Balances',
            route: 'route-customer-balance',
            resource: 'resource_tools_shipping_label',
            action: 'read',
            icon: 'bi-cash-coin',
          },
        ],
      },
      {
        title: 'Merchant Connect',
        route: 'merchant-connect',
        icon: 'bi-globe2',
        resource: 'resource_management_finance_tools',
        action: 'read',
      },
    ],
  },
]

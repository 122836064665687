/*

NOTE: tag is not supported in horizontal menu

Array of object

Top level object can be:
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- nav Grp (top level grp is called header group) ---/

title
icon (if it's on top/second level)
children

/--- nav Item (top level item is called header link) ---/

icon (if it's on top/second level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)

*/
import dashboard from './dashboard'
import inbound from './inbound'
import inventory from './inventory'
import outbound from './outbound'
import reports from './reports'
import tools from './tools'
import management from './management'
import wms from './wms'
import account from './account'
import settings from './settings'
import admin from './admin'

// Array of sections
export default [...dashboard, ...inbound, ...inventory, ...outbound, ...reports, ...tools, ...management, ...wms, ...account, ...settings, ...admin]

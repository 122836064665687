/* eslint-disable no-unused-vars */
import { exportDataGrid } from 'devextreme/excel_exporter'
import ExcelJS from 'exceljs'
import saveAs from 'file-saver'
import Pageable from '@robustshell/components/Grid/dxPageable'

export default {
  data() {
    return {
      pageable: new Pageable(),
    }
  },
  computed: {
    pageableResult: {
      get() {
        return this.pageable.toCreatePageable()
      },
    },
    setHeight() {
      return window.innerHeight / 1.2
    },
  },

  methods: {
    setPagination(context) {
      this.pageable.setContext(context)
    },
    onContentReady(e) {
      e.component.option('loadPanel.enabled', false)
    },
    onInitialized(e) {
    },
    onExporting(e) {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('ReceiveItems')
      exportDataGrid({
        component: e.component,
        worksheet: worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer().then(buffer => {
          saveAs(
            new Blob([buffer], { type: 'application/octet-stream' }),
            'Items.xlsx',
          )
        })
      })
      e.cancel = true
    },
  },
}

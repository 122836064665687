<template>
  <div class="d-flex text-left p-1 align-items-center">
    <p-icon :name="itemData === 'Photo' ? 'feather-image' : 'psh-barcode'" size="24px" />
    <div class="text-truncate">
      <div class="ml-1">
{{ itemData }}
</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    itemData: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>

</style>

<template>
  <div>
    <div>
      <b-avatar
        :id="'technical-support'"
        badge-top
        rounded
        button
        badge-offset="-6px"
        icon="wrench"
        variant="warning"
        class="tech-support"
        @click="openTechnicalSupport"
      />
      <dx-util-popup
        ref="technicalSupportRef"
        :show-close-button="true"
        :drag-enabled="false"
        :close-on-outside-click="false"
        :show-title="true"
        :full-screen="false"
        position="right"
        :animation="{ show: { type: 'slideIn', direction: 'right', duration: 500, easing: 'ease-in-out' }, hide: { type: 'slideOut', duration: 500, direction: 'right', easing: 'ease-in-out' } }"
        :title="technicalSupportTitle"
        width="600"
        height="100%"
        :style="{ transition: 'all 0.3s ease-in-out' }"
        @shown="onTechnicalSupportShown"
        @hidden="onTechnicalSupportHidden"
      >
        <div>
          <dx-util-scroll-view width="100%" height="100%">
            <div class="px-1">
              <div class="alert alert-danger my-1 mb-1">
                Use this form for only PrepShipHub Software issues!
              </div>
              <div class="alert alert-warning my-1 mb-3">
                Don't share any buyer data (name, address, phone or email) in this form.
              </div>
              <dx-util-select-box
                v-model="formData.issueType"
                label="Issue Type"
                class="my-1"
                :items="issueTypes"
              />
              <dx-util-text-box
                v-if="hasPermission"
                v-model="accountNo"
                :visible="isCompanyTypeWarehouse"
                label="Account or Suite No"
                class="my-2"
                @focus-out="onFocusOut"
                @key-down="getCompanyByAccount"
              >
                <dx-text-box-button
                  location="after"
                  name="accountTextBoxButton"
                  :options="accountTextBoxButtonOptions"
                />
              </dx-util-text-box>
              <dx-util-select-box
                v-model="formData.storeId"
                label="Store"
                :data-source="stores"
                display-expr="text"
                value-expr="value"
                class="my-2"
              />
              <dx-util-text-box
                v-model="formData.title"
                label="Title"
                class="my-2"
              />
              <dx-util-text-area
                v-model="formData.description"
                label="Description"
                class="my-2"
                height="200"
                :auto-resize-enabled="true"
              />
              <service-folder-file-manager
                ref="refServiceFolderFileManager"
                :company-id="userCompanyId"
                :upload-document-classes="['NONE_CLASS']"
                :entity-folder-id="uploadUID"
                upload-document-class="NONE_CLASS"
                service-folder="SUPPORTTICKET"
                class="px-0"
              />
              <dx-util-button text="Create Ticket" type="success" class="w-100 my-1" @click="createTicket" />
            </div>
          </dx-util-scroll-view>
        </div>
      </dx-util-popup>
    </div>
  </div>
</template>

<script>
import companyService from '@/http/requests/company/companyService'
import { ref, onMounted, reactive } from '@vue/composition-api'
import { useRouter } from '@core/utils/utils'
import useCurrentUser from '@/libs/app/current-user'
import { Notify } from '@/@robustshell/utils'
import { v4 as uuidv4 } from 'uuid'
import commonEntityService from '@/http/requests/common/commonEntityService'
import { DxButton as DxTextBoxButton } from 'devextreme-vue/text-box'
import ServiceFolderFileManager from '@components/service-folder/ServiceFolderFileManager.vue'
import { SupportTicketStatusEnum } from '@/enums'

export default {
  components: {
    DxTextBoxButton,
    'service-folder-file-manager': ServiceFolderFileManager,
  },
  setup() {
    const issueTypes = ref()
    async function getIssueTypes() {
      const result = await companyService.getIssueTypes()
      issueTypes.value = result
    }

    const { router } = useRouter()
    async function goToTicketGrid() {
      router.push({ name: 'route-tickets' }).catch(() => {})
    }

    const accountNo = ref('')
    const stores = ref([])
    const formData = reactive({
      issueType: '',
      filePath: '',
      url: '',
      storeId: '',
      companyId: '',
      title: '',
      description: '',
      ticketStatus: '',
    })

    function clearFormData() {
      formData.issueType = ''
      formData.filePath = ''
      formData.storeId = 0
      formData.url = ''
      formData.title = ''
      formData.companyId = 0
      formData.description = ''
      formData.ticketStatus = ''
      accountNo.value = null
    }

    onMounted(() => {
      getIssueTypes()
    })

    const { isCompanyTypeWarehouse, userCompanyId } = useCurrentUser()
    return {
      issueTypes,
      goToTicketGrid,
      formData,
      accountNo,
      isCompanyTypeWarehouse,
      stores,
      clearFormData,
      userCompanyId,
    }
  },
  data() {
    return {
      technicalSupportTitle: '',
      uploadUID: uuidv4(),
    }
  },
  computed: {
    hasPermission() {
      return this.$can('read', 'resource_customer_account_no')
    },
    accountTextBoxButtonOptions() {
      return {
        text: 'Get Account',
        type: 'default',
        onClick: () => {
          const accountNo = this.accountNo.replace(/\D/g, '')
          this.getCompanyByAccountNo(accountNo)
        },
      }
    },
    technicalSupportPopup() {
      return this.$refs.technicalSupportRef.instance
    },
  },
  mounted() {
    this.getStores()
  },
  methods: {
    async createTicket() {
      if (!this.formData.issueType || !this.formData.title || !this.formData.description) {
        Notify.error('Please fill title, description and issue type')
        return
      }
      this.formData.filePath = this.upload
      this.formData.url = this.$route.path
      this.formData.ticketStatus = SupportTicketStatusEnum.OPEN.value
      await companyService.createTicket(this.formData)
      this.technicalSupportPopup.hide()
    },
    openTechnicalSupport() {
      this.technicalSupportPopup.show()
    },
    onTechnicalSupportShown() {
      this.technicalSupportTitle = 'Create Technical Support Ticket'
    },
    onTechnicalSupportHidden() {
      this.clearFormData()
    },
    onFocusOut(e) {
      const accountNo = e.event.target.value
      if (!accountNo) {
        return
      }
      this.accountNo = accountNo.replace(/\D/g, '')
      this.getCompanyByAccountNo(this.accountNo)
    },
    getCompanyByAccount(e) {
      if (e.event.keyCode !== 13) return
      const accountNo = e.event.target.value
      this.accountNo = accountNo.replace(/\D/g, '')
      this.getCompanyByAccountNo(this.accountNo)
    },
    getCompanyByAccountNo(accountNo) {
      if (!accountNo || accountNo === null || accountNo === 0 || accountNo === '' || accountNo === undefined) {
        Notify.error('Please enter a valid account no')
        return
      }
      companyService.fetchByAccountNo(accountNo).then(result => {
        this.formData.companyId = result.data.id
        this.accountNo = accountNo
        this.getStores()
      })
    },
    getStores() {
      if (this.isCompanyTypeWarehouse && this.accountNo) {
        commonEntityService
          .fetchStoresByParentCompanyAccountNo(this.accountNo)
          .then(result => {
            const { data } = result
            this.setStores(data)
          })
      } else {
        commonEntityService.fetchStoresForCurrentCompany().then(result => {
          const { data } = result
          this.setStores(data)
        })
      }
    },
    setStores(data) {
      this.stores.splice(0, this.stores.length)
      if (data.length > 1) {
        this.stores.push({ value: 0, text: 'Select a store' })
      }
      data.forEach(element => {
        this.stores.push({ value: element.id, text: element.name })
      })
      if (this.stores.length === 1) {
        this.formData.storeId = this.stores[0].value
      }
    },
  },
}
</script>

<style lang="scss">
.tech-support {
  .b-avatar-badge {
    font-size: 100% !important;
  }
}

</style>

<template>
  <b-nav-item class="customizer-toggle" @click="onCustomizerOpen">
    <feather-icon
        icon="SettingsIcon"
        size="15"
      />
  </b-nav-item>
</template>

<script>
import store from '@/store'

export default {
  setup() {
    const isCustomizerOpen = store.getters['app/getIsCustomizerOpen']
    function onCustomizerOpen() {
      store.dispatch('app/updateCustomizerOpenStatus', !isCustomizerOpen)
    }
    return { onCustomizerOpen }
  },
}
</script>

export default [
  {
    title: 'Inventory',
    icon: 'feather-layers',
    children: [
      {
        title: 'Product Catalog',
        route: 'route-products',
        icon: 'feather-tag',
        resource: 'resource_product_products',
        action: 'read',
      },
      {
        title: 'Receive Inventory',
        route: 'route-receive-batch-account-select',
        icon: 'bi-ui-checks',
        resource: 'resource_inbound_receive_batch',
        action: 'read',
      },
      {
        title: 'Location Actions',
        route: 'warehouse-action',
        resource: 'resource_management_warehouse_location',
        action: 'read',
        icon: 'psh-package-3',
      },
      {
        title: 'Warehouse Inventory',
        icon: 'psh-shelves',
        children: [
          {
            title: 'Prep',
            route: { name: 'prep-inventory', params: { itemAction: 'fba' } },
            icon: 'psh-package-2',
            resource: 'resource_inventory_prep_inventory',
            action: 'read',
          },
          {
            title: 'WFS',
            route: { name: 'wfs-inventory', params: { itemAction: 'wfs' } },
            icon: 'psh-package-2',
            resource: 'resource_inventory_wfs_inventory',
            action: 'read',
          },
          {
            title: 'FBM',
            route: { name: 'fbm-inventory', params: { itemAction: 'fbm' } },
            icon: 'psh-package-2',
            resource: 'resource_inventory_fbm_inventory',
            action: 'read',
          },
          {
            title: 'Storage',
            route: { name: 'storage-inventory', params: { itemAction: 'storage' } },
            icon: 'psh-package-2',
            resource: 'resource_inventory_storage_inventory',
            action: 'read',
          },
          {
            title: 'Forwarding',
            route: { name: 'forwarding-inventory', params: { itemAction: 'forwarding' } },
            icon: 'psh-package-2',
            resource: 'resource_inventory_forwarding_inventory',
            action: 'read',
          },
          {
            title: 'Return',
            route: { name: 'return-inventory', params: { itemAction: 'return' } },
            icon: 'psh-package-2',
            resource: 'resource_inventory_return_inventory',
            action: 'read',
          },
          {
            title: 'Removal Order',
            route: { name: 'removal-order-inventory', params: { itemAction: 'removal_order' } },
            icon: 'psh-package-2',
            resource: 'resource_inventory_removal_order_inventory',
            action: 'read',
          },
          {
            title: 'Dropship',
            route: { name: 'dropship', params: { itemAction: 'dropship' } },
            icon: 'psh-package-2',
            resource: 'resource_inventory_dropship_inventory',
            action: 'read',
          },
        ],
      },
      {
        title: 'Amazon Inventory',
        route: 'amazon-inventory',
        resource: 'resource_inventory_prep_inventory',
        action: 'read',
        icon: 'psh-package-3',
      },
      {
        title: 'Dispositions',
        name: 'dispositions',
        icon: 'psh-inspection',
        route: { name: 'dispositions' },
        resource: 'resource_inventory_prep_inventory',
        action: 'read',
      },
    ],
  },
]

<template>
    <div id="app-breadcrumb" class="content-header d-none d-sm-block">
      <div
        v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
        class="app-breadcrumb-padding"
      >
        <div class="d-flex flex-row justify-content-start align-items-center">
          <div class="align-self-center">
            <h6 class="content-header-title float-left pr-1 mb-0">
              {{ $route.meta.pageTitle }}
            </h6>
          </div>
          <div class="breadcrumb flex-grow-1">
            <router-link to="/">
              <p-icon
                name="feather-home"
                size="14px"
                class="align-text-top"
              />
            </router-link>
            <ul class="breadcrumb">
              <li
                v-for="item in $route.meta.breadcrumb"
                :key="item.text"
              >
                <router-link v-if="item.withQuery" :to="{ name: item.name,
                  params: {
                    accountNo: $route.query.accountNo,
                    companyId: $route.query.companyId,
                    companyName: $route.query.companyName,
                  },
                  query: {
                    qAccountNo: $route.query.qAccountNo,
                    qWarehouseId: $route.query.qWarehouseId,
                    qStoreId: $route.query.qStoreId,
                    qStatus: $route.query.qStatus,
                    },
                  }"
                >
                  {{ item.text }}
                </router-link>
                <router-link v-else-if="item.to" :to="item.to">
                  {{ item.text }}
                </router-link>
                <span v-else>
                  {{ item.text }}
                </span>
              </li>
            </ul>
          </div>

          <div v-if="remainingTrialDays && remainingTrialDays.length > 0" class="d-flex">
            <span class="badge badge-light-warning mr-half">
              {{ firstPlan.trialRemaining }} days left
            </span> for {{ firstPlan.planName }}
            <div id="trialRemaining" class="pulse">
              <p-icon
                name="bi-exclamation-circle-fill"
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        <dx-util-popover
          v-if="remainingTrialDays && remainingTrialDays.length > 1"
          :width="300"
          target="#trialRemaining"
          show-event="dxhoverstart"
          hide-event="dxhoverend"
        >
          <div v-for="(item, index) in remainingTrialDays" :key="index" class="d-flex justify-content-between p-half">
            {{ item.planName }} <span class="badge badge-light-warning">{{ item.trialRemaining }} days left</span>
          </div>
        </dx-util-popover>
      </div>
    </div>
</template>

<script>
import useCurrentUser from '@/libs/app/current-user'

export default {
  components: {},
  setup() {
    const {
      userCompanyId, remainingTrialDays,
    } = useCurrentUser()
    const firstPlan = remainingTrialDays.value.length > 0 ? remainingTrialDays.value[0] : []
    return {
      userCompanyId,
      remainingTrialDays,
      firstPlan,
    }
  },
}
</script>
<style lang="scss" scoped>
#app-breadcrumb{
  .app-breadcrumb-padding {
    padding-top: 0.286rem;
    padding-bottom: 0.286rem;
  }
}
.breadcrumb {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0.1em 2em 0.1em 1em;
}

.breadcrumb li {
  display: inline-block;
  margin-right: 1.5em;
}

.breadcrumb li:last-child {
  margin-right: 0;
}
.pulse {
      animation: pulse 1s infinite;
      padding-left: 4px;
      color: orange;
    }

    @keyframes pulse {
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(1.2);
      }
      100% {
        transform: scale(1);
      }
    }
</style>

import axios from '@/libs/axios'
import endpoints from '@/http/endpoints/index'

export default {
  // get all notification
  async findAll(params) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.notification.Controller.findAll(params))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },
  // find by notification id
  async fetchById(id) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.notification.Controller.findById(id))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  // create notification
  async create(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.notification.Controller.create(), data)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  // update notification
  async update(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .put(endpoints.notification.Controller.update(), data)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  // delete notification by id
  async deleteById(id) {
    const promise = new Promise((resolve, reject) => {
      axios
        .delete(endpoints.notification.Controller.delete(id))
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },
  // get all notification Topic
  async notificationList() {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.notification.Controller.notificationList())
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },
  // get all subsribed user list
  async fetchSubscribedUsersByCompanyIdAndNotificationTopicName(companyId, notificationTopicName) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.notification.Controller.fetchSubscribedUsersByCompanyIdAndNotificationTopicName(companyId, notificationTopicName))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },
  async updateCompanyNotificationTopicSubscribedUsers(companyId, topic, userIdList) {
    const promise = new Promise((resolve, reject) => {
      axios
        .put(endpoints.notification.Controller.updateCompanyNotificationTopicSubscribedUsers(companyId, topic), userIdList)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  async updateNotificationList(notificationIdList) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.notification.Controller.updateNotification(), notificationIdList)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },
  async popNotification(listItem) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.notification.Controller.popNotification(), listItem)
        .then(response => {
          resolve(
            response.data.body.content ? response.data.body.content : response.data.body,
          )
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },
  async popNotificationUnReadCount() {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.notification.Controller.popNotificationUnReadCount())
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },
  async fetchAll(pageable) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.notification.Controller.fetchAll(pageable))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },
}
